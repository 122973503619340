import React from "react";

const Layout = ({ children }) => {
  return (
    <div className="flex flex-col w-full max-w-6xl overflow-hidden py-10 px-0 md:p-10 min-h-screen text-gray-700">
      {children}
    </div>
  );
};

export default Layout;
