import React from "react";
import Layout from "../components/Layout";
import { GET_ALL_TOOLBOX_BOOKS } from "../components/getQueries";

import { graphql } from "react-apollo";
import TitleContainer from "../components/TitleContainer";
import { useQuery } from "@apollo/client";
const ToolboxBookPage = () => {
  const { loading, error, data } = useQuery(GET_ALL_TOOLBOX_BOOKS);
  console.log(data);
  return (
    !loading && (
      <Layout>
        <div className="flex flex-col h-full py-2">
          <TitleContainer>Books</TitleContainer>
        </div>
        <div className="flex flex-row flex-wrap">
          {data?.toolboxBooks?.map((item) => (
            <VideoItem key={item?.id} data={item} />
          ))}
        </div>
      </Layout>
    )
  );
};

export default ToolboxBookPage;

const VideoItem = ({ data }) => {
  return (
    <div
      onClick={() => window.open(data.link, "_blank")}
      className="  space-y-4 w-full sm:w-full md:w-80  cursor-pointer hover:bg-blue-300 hover:shadow-md flex justify-between flex-col  bg-blue-400 p-2 text-white text-lg break-normal rounded-lg mr-4 mb-4">
      <span className="p-2 bg-gray-700 rounded-md bg-opacity-60 text-2xl">
        {data.title}
      </span>
      <span className="p-2 bg-gray-700 rounded-md ">
        About book: {data.about}
      </span>
      <div className="flex flex-row flex-wrap space-x-2">
        <span>Author: {data.author}</span>

        <span>Grade: {data.grade}</span>
        <span> Year: {data.year}</span>
      </div>
      <span>check the book</span>
    </div>
  );
};
