import _ from "lodash";
import React, { useEffect, useState } from "react";
import { LANDING_PAGE_CONTENT } from "../components/getQueries";
import { graphql } from "react-apollo";
import Layout from "../components/Layout";
import { useApolloClient, useQuery } from "@apollo/client";
import LoadingPage from "../components/LoadingPage";
import axios from "axios";
const HomePage = () => {
  const [leaderBoard, setLeaderBoard] = useState([]);
  const getLeaderBoard = async () => {
    axios
      .get("https://hasura-c54b21aa.nhost.app/api/rest/getleaderboard")
      .then((res) => {
        console.log(res);
        setLeaderBoard(res?.data?.leader_board);
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => getLeaderBoard(), []);
  const { loading, error, data } = useQuery(LANDING_PAGE_CONTENT);
  return loading ? (
    <LoadingPage />
  ) : (
    <Layout>
      <div className="flex flex-col space-y-4 w-full relative">
        <div className=" w-full">
          <img
            src={_.last(data?.landingPages)?.heroPhoto?.url}
            alt=""
            className=" rounded-2xl shadow-lg transition duration-1000 ease-out  transform hover:-translate-y-1 hover:scale-105"
          />
        </div>
        <div className="flex flex-row w-full justify-end absolute -bottom-10 sm:-bottom-10 md:bottom-10">
          <div className="  sm:flex  bottom-6 right-0 w-full sm:w-full md:w-1/2 items-center justify-center flex flex-col">
            <div className=" cursor-default p-4   bg-yellow-400  items-center flex flex-row justify-center rounded-xl shadow-lg transition duration-1000 ease-out  transform hover:-translate-y-1 hover:scale-105  ">
              <span className=" text-2xl md:text-5xl text-white text-left header-main ">
                {_.last(data?.landingPages)?.heroMessage}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col space-y-4 px-2 py-12">
        <span className="text-3xl header-second">
          {_.last(data?.landingPages)?.bodyTopMainTitle}
        </span>
        <div className="flex  flex-wrap w-full justify-center sm:justify-center md:justify-between">
          <TopBodyBox
            title={_.last(data?.landingPages)?.bodyTopTitle1}
            desc={_.last(data?.landingPages)?.bodyTopDesc1}
          />
          <TopBodyBox
            title={_.last(data?.landingPages)?.bodyTopTitle2}
            desc={_.last(data?.landingPages)?.bodyTopDesc2}
          />
          <TopBodyBox
            title={_.last(data?.landingPages)?.bodyTopTitle3}
            desc={_.last(data?.landingPages)?.bodyTopDesc3}
          />
          <TopBodyBox
            title={_.last(data?.landingPages)?.bodyTopTitle4}
            desc={_.last(data?.landingPages)?.bodyTopDesc4}
          />
        </div>
      </div>
      <div className="flex flex-col space-y-10 px-4">
        <CenterBodyBox
          title={_.last(data?.landingPages)?.centerBodyTitle1}
          desc={_.last(data?.landingPages)?.centerBodyDesc1}
          image={_.last(data?.landingPages)?.centerBodyImage1?.url}
        />
        <CenterBodyBox
          title={_.last(data?.landingPages)?.centerBodyTitle2}
          desc={_.last(data?.landingPages)?.centerBodyDesc2}
          image={_.last(data?.landingPages)?.centerBodyImage2?.url}
          odd={true}
        />
        <CenterBodyBox
          title={_.last(data?.landingPages)?.centerBodyTitle3}
          desc={_.last(data?.landingPages)?.centerBodyDesc3}
          image={_.last(data?.landingPages)?.centerBodyImage3?.url}
        />
        {/* {!_.isEmpty(leaderBoard) && (
          <CenterBodyBox
            odd={true}
            image="https://graphly.io/wp-content/uploads/leaderboards-podium-star.jpg"
            title="LeaderBoard"
            desc={
              <div className="flex flex-col items-center space-y-4 cursor-default">
                {leaderBoard.map((item, index) => (
                  <div
                    key={index}
                    className="flex p-2 flex-row text-lg text-gray-700 font-light w-full max-w-xs justify-between border-b-2 bg-gray-50">
                    <span>{item?.name.school_name}</span>
                    <span> {item?.project_final_points}</span>
                  </div>
                ))}
              </div>
            }
          />
        )} */}
      </div>

      <div className="flex flex-col p-4 cursor-default">
        <div
          className="w-full  p-14 flex flex-row flex-wrap text-white space-x-10 items-center justify-between z-20  bg-pink-500 rounded-xl
      transition duration-1000 ease-out  transform hover:-translate-y-1 hover:scale-105 shadow-lg
      
      
      ">
          <div>
            <span className="  font-bold header-main text-4xl">
              {_.last(data?.landingPages)?.actionSectionTitle}
            </span>
          </div>
          <div>
            <button
              className=" btn-outline rounded-full bg-white  border-white hover:border-pink-200 "
              onClick={() =>
                window.open("https://app.seep.education/register", "_self")
              }>
              {_.last(data?.landingPages)?.actionSectionButtonLabel}
            </button>
          </div>
        </div>
      </div>

      <br />
      <br />
      <br />
      <br />
      <br />
    </Layout>
  );
};

export default HomePage;

const TopBodyBox = ({ title, desc }) => {
  return (
    <div className="flex flex-col space-y-2 w-full  max-w-md  mb-6 mr-6">
      <span className="header-second">{title}</span>
      <span className="body-main">{desc} </span>
    </div>
  );
};

const CenterBodyBox = ({ title, desc, image, odd }) => {
  return (
    <div
      className={`flex ${
        odd
          ? " flex-col sm:flex-col md:flex-row"
          : " flex-col sm:flex-col md:flex-row-reverse"
      } space-y-2 w-full  items-center space-x-0 sm:space-x-1 md:space-x-10   `}>
      <div
        className=" w-full sm:w-full md:w-1/2  p-8 transition duration-1000 ease-in-out  transform hover:-translate-y-1 hover:scale-110"
        style={{
          backgroundImage: "url(/assets/imagebg.svg)",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
        }}>
        <img
          src={image}
          alt=""
          className="rounded-xl shadow-lg transition duration-500 ease-in-out bg-blue-600 hover:bg-red-600 transform hover:-translate-y-1 hover:scale-110"
        />
      </div>{" "}
      <div className="w-full sm:w-full md:w-1/2 flex flex-col space-y-4">
        <span className="text-2xl header-second ">{title}</span>
        <span className="body-main">{desc} </span>
      </div>
    </div>
  );
};
