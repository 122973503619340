import React from "react";

const LoadingPage = () => {
  return (
    <div className="flex flex-col w-screen h-screen items-center justify-center">
      <img
        src="https://media.graphcms.com/4cZaQ2xsT5WGknQJRQok"
        className=" w-32 h-32"
        alt=""
      />
    </div>
  );
};

export default LoadingPage;
