import React from "react";

const FormItem = ({ label, item, horizontal }) => {
  return (
    <div
      className={` flex ${
        horizontal
          ? "flex-row items-center space-x-2"
          : "flex-col max-w-sm items-start mr-4 mb-6 "
      }  space-y-1 w-full `}>
      <div className=" cursor-default text-lg font-light text-gray-500">
        {label}
      </div>
      <div className="w-full">{item}</div>
    </div>
  );
};

export default FormItem;
