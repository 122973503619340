import React from "react";
import Layout from "../components/Layout";
import { GET_TOOLBOX_ITEM } from "../components/getQueries";
import { graphql } from "react-apollo";
import { Link, useParams } from "react-router-dom";
import TitleContainer from "../components/TitleContainer";
import { gql, useQuery } from "@apollo/client";
import parse from "html-react-parser";
import ToolboxVideoPage from "./ToolboxVideoPage";
import LoadingPage from "../components/LoadingPage";

const ToolboxViewerPage = () => {
  const { id } = useParams();
  const { loading, error, data } = useQuery(GET_TOOLBOX_ITEM, {
    variables: { id: id },
  });

  return id && loading ? (
    <LoadingPage />
  ) : (
    <Layout>
      <div>
        <Link to="/toolbox" className="no-underline">
          <button className="btn-generic rounded-full text-xl shadow-md">
            Back to toolbox
          </button>
        </Link>
      </div>
      <TitleContainer>{data?.toolboxArticle?.title}</TitleContainer>
      <div className="flex flex-row   flex-wrap justify-between ">
        <div className="flex flex-col h-full py-2 max-w-full md:max-w-xl  mr-4 mb-4 ">
          <span className=" text-2xl text-gray-700 py-2">
            {data?.toolboxArticle?.shortText}
          </span>
          <span className=" text-2xl text-gray-700 py-2">
            {parse(data?.toolboxArticle?.longText?.html)}
          </span>
        </div>
        <div>
          <img
            className=" max-w-full  md:max-w-sm rounded-xl"
            src={data?.toolboxArticle?.image?.url}
            alt=""
          />
        </div>
      </div>
      <div className="flex flex-col p-4  py-6 text-4xl border-b border-pink-600">
        <span>Materials</span>
      </div>
      <div className="flex flex-row flex-wrap p-6 border-b border-pink-600">
        {data?.toolboxArticle?.document1 && (
          <div
            onClick={() =>
              window.open(data?.toolboxArticle?.document1, "_blank")
            }
            className="flex flex-col w-32 h-20 header-main bg-pink-600 leading-5	 rounded-2xl p-2 items-center justify-center text-2xl text-white cursor-pointer hover:shadow-sm mr-6 mb-6">
            {data?.toolboxArticle?.titleDocument1 || "Download"}{" "}
          </div>
        )}
        {data?.toolboxArticle?.document2 && (
          <div
            onClick={() =>
              window.open(data?.toolboxArticle?.document2, "_blank")
            }
            className="flex flex-col w-32 h-20 header-main bg-orange-600 leading-5	 rounded-2xl p-2 items-center justify-center text-2xl text-white cursor-pointer hover:shadow-sm mr-6 mb-6">
            {data?.toolboxArticle?.titleDocument2 || "Download"}{" "}
          </div>
        )}
        {data?.toolboxArticle?.document3 && (
          <div
            onClick={() =>
              window.open(data?.toolboxArticle?.document3, "_blank")
            }
            className="flex flex-col w-32 h-20 header-main bg-blue-600 	 leading-5	 rounded-2xl p-2 items-center justify-center  text-2xl text-white  cursor-pointer hover:shadow-sm mr-6 mb-6">
            {data?.toolboxArticle?.titleDocument3 || "Download"}{" "}
          </div>
        )}
        {data?.toolboxArticle?.document4 && (
          <div
            onClick={() =>
              window.open(data?.toolboxArticle?.document4, "_blank")
            }
            className="flex flex-col w-32 h-20 header-main bg-green-600 leading-5	 rounded-2xl p-2 items-center justify-center text-2xl text-white cursor-pointer hover:shadow-sm mr-6 mb-6">
            {data?.toolboxArticle?.titleDocument4 || "Download"}{" "}
          </div>
        )}
        {data?.toolboxArticle?.document5 && (
          <div
            onClick={() =>
              window.open(data?.toolboxArticle?.document5, "_blank")
            }
            className="flex flex-col w-32 h-20 header-main leading-1 bg-indigo-600 leading-5	 rounded-2xl p-2 items-center justify-center text-2xl text-white cursor-pointer hover:shadow-sm mr-6 mb-6">
            {data?.toolboxArticle?.titleDocument5 || "Download"}{" "}
          </div>
        )}
        {data?.toolboxArticle?.document6 && (
          <div
            onClick={() =>
              window.open(data?.toolboxArticle?.document6, "_blank")
            }
            className="flex flex-col w-32 h-20 header-main bg-pink-600 leading-5	 rounded-2xl p-2 items-center justify-center text-2xl text-white cursor-pointer hover:shadow-sm mr-6 mb-6">
            {data?.toolboxArticle?.titleDocument6 || "Download"}{" "}
          </div>
        )}
      </div>
      {data?.toolboxArticle?.videoFilter && (
        <ToolboxVideoPage filter={data?.toolboxArticle?.videoFilter || null} />
      )}{" "}
    </Layout>
  );
};

export default ToolboxViewerPage;

const ToolboxItem = ({ title, id }) => {
  return (
    <Link
      to={`/view/${id}`}
      className="flex flex-col space-y-4 border-b-2 cursor-pointer hover:bg-pink-200 max-w-md w-full mr-2 mb-2  py-4 bg-gray-100 p-2 rounded-lg">
      <div>
        <span className="text-xl font-bold">{title}</span>
      </div>
    </Link>
  );
};
