import React, { useState } from "react";
import { Link } from "react-router-dom";
import { graphql } from "react-apollo";
import { GET_MAIN_HEADER, GET_TOOLBOX_LIST } from "./getQueries";
import _ from "lodash";
import { useQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
const MainHeader = () => {
  const { loading, error, data } = useQuery(GET_MAIN_HEADER);

  !loading && console.log(data);
  const [menuOpen, setMenuOpen] = useState(false);
  const [showToolboxMenu, setShowToolboxMenu] = useState(false);
  const {
    loading: toolboxDataLoading,
    error: toolboxDataError,
    data: toolboxData,
  } = useQuery(GET_TOOLBOX_LIST);

  const history = useHistory();
  const goToToolbox = () => {
    history.push("/toolbox");
    setMenuOpen(false);
    setShowToolboxMenu(false);
  };
  const closeAllMenus = () => {
    setMenuOpen(false);
    setShowToolboxMenu(false);
  };

  const menuItem = (row) => {
    return (
      !loading && (
        <nav className="z-100">
          <ul
            className={`${
              row ? "flex flex-row space-x-4 " : "flex flex-col space-y-2"
            }  items-center text-2xl  text-white`}>
            <li>
              <Link
                className="no-underline text-white hover:underline header-main"
                to="/"
                onClick={() => closeAllMenus()}>
                {_.last(data?.mainHeaders)?.home}
              </Link>
            </li>
            <li>
              <Link
                className="no-underline text-white hover:underline header-main"
                to="/about"
                onClick={() => closeAllMenus(false)}>
                {_.last(data?.mainHeaders)?.about}
              </Link>
            </li>
            <li>
              <Link
                className="no-underline text-white hover:underline header-main"
                to="/partners"
                onClick={() => closeAllMenus(false)}>
                {_.last(data?.mainHeaders)?.partner}
              </Link>
            </li>
            <li>
              <Link
                className="no-underline text-white hover:underline header-main"
                to="/leader"
                onClick={() => closeAllMenus(false)}>
                {_.last(data?.mainHeaders)?.leader}
              </Link>
            </li>
            <li>
              <Link
                className="no-underline text-white hover:underline header-main"
                to="/gallery"
                onClick={() => closeAllMenus(false)}>
                {_.last(data?.mainHeaders)?.gallery}
              </Link>
            </li>
            <li>
              <div
                className="hover:underline cursor-pointer header-main"
                onClick={() =>
                  showToolboxMenu
                    ? goToToolbox()
                    : setShowToolboxMenu(!showToolboxMenu)
                }>
                {_.last(data?.mainHeaders)?.toolbox}
              </div>
              {showToolboxMenu && (
                <div className=" cursor-pointer flex flex-col absolute p-2 rounded-md bg-green-500    w-60 text-md">
                  <ToolBoxMenuList
                    data={toolboxData}
                    setMenuOpen={(e) => closeAllMenus(e)}
                  />
                </div>
              )}
            </li>
            <li>
              <Link
                className="no-underline text-white hover:underline header-main"
                to="/blog"
                onClick={() => closeAllMenus(false)}>
                Blog
              </Link>
            </li>
            {/* <li>
              <Link to="/projectdetail" onClick={() => setMenuOpen(false)}>
                {_.last(data?.mainHeaders)?.projectdetail}
              </Link>
            </li> */}
            <li>
              <Link
                className="no-underline text-white hover:underline header-main"
                to="/contact"
                onClick={() => closeAllMenus(false)}>
                {_.last(data?.mainHeaders)?.contact}
              </Link>
            </li>
            <li>
              <button
                className=" header-main btn-outline  text-white border-white hover:bg-green-700 rounded-full "
                onClick={() =>
                  window.open("https://app.seep.school/register", "_self")
                }>
                Register
              </button>
            </li>
            <li>
              <button
                className="btn-generic header-main   border-0  rounded-full bg-green-600"
                onClick={() =>
                  window.open("https://app.seep.school/login", "_self")
                }>
                Login
              </button>
            </li>
          </ul>
        </nav>
      )
    );
  };
  return (
    <div className="flex flex-row w-full items-center z-100   p-2   justify-center">
      <div className="flex flex-row w-full items-center justify-between  max-w-6xl ">
        <Link to="/">
          <div className="flex flex-row items-center space-x-4 p-2 bg-white rounded-full shadow-md">
            <img
              src="https://media.graphcms.com/4cZaQ2xsT5WGknQJRQok"
              className=" w-32"
              alt=""
            />
          </div>
        </Link>
        <div className="hidden sm:hidden md:flex">{menuItem(true)}</div>
        <div
          className="flex sm:flex md:hidden cursor-pointer text-white p-2 rounded-lg bg-green-500 shadow-sm"
          onClick={() => setMenuOpen(true)}>
          menu
        </div>
        {menuOpen && (
          <div className="flex flex-col w-full h-full top-0 left-0 absolute z-100 p-4  bg-green-800 space-y-4">
            <div
              className="flex flex-row w-full justify-end items-center cursor-pointer p-1  text-white "
              onClick={() => setMenuOpen(false)}>
              close
            </div>
            <div className="flex flex-row items-center justify-center w-full text-2xl font-bold">
              <div className="flex flex-row items-center space-x-4 p-2 bg-white rounded-full shadow-md">
                <img
                  src="https://media.graphcms.com/4cZaQ2xsT5WGknQJRQok"
                  className=" w-20"
                  alt=""
                />
              </div>
            </div>
            <div>{menuItem(false)}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MainHeader;

const ToolBoxMenuList = ({ setMenuOpen, data }) => {
  return (
    <div className="flex flex-col ">
      {data?.toolboxArticles?.map((item) => (
        <ToolboxItem
          key={item?.id}
          id={item?.id}
          title={item?.title}
          setMenuOpen={() => setMenuOpen(false)}
        />
      ))}
      <Link
        className="   no-underline p-1  hover:bg-green-800   flex-col items-center text-start text-sm  text-white cursor-pointer hover:shadow-sm  justify-center"
        to="/blog"
        onClick={() => setMenuOpen(false)}>
        <span className="header-main cursor-pointer ">BLOG</span>
      </Link>
      <Link
        className="  no-underline p-1 hover:bg-green-800     flex-col items-center text-start text-sm  text-white cursor-pointer hover:shadow-sm  justify-center"
        to="/toolboxvideo"
        onClick={() => setMenuOpen(false)}>
        <div>
          <span className="header-main cursor-pointer ">VIDEOS</span>
        </div>
      </Link>
      <Link
        to="/toolboxbook"
        className="  no-underline p-1  hover:bg-green-800   flex-col items-center text-start text-sm  text-white cursor-pointer hover:shadow-sm  justify-center"
        onClick={() => setMenuOpen(false)}>
        <div>
          <span className="header-main  cursor-pointer">BOOKS</span>
        </div>
      </Link>
    </div>
  );
};
const ToolboxItem = ({ title, id, setMenuOpen }) => {
  return (
    <Link
      onClick={() => setMenuOpen(false)}
      to={`/view/${id}`}
      className="  no-underline p-1  hover:bg-green-800    flex-col items-center text-start text-sm  text-white cursor-pointer hover:shadow-sm  justify-center">
      <div>
        <span className="header-main cursor-pointer  ">
          {title.toUpperCase()}
        </span>
      </div>
    </Link>
  );
};
