import React from "react";

const TitleContainer = ({ children }) => {
  return (
    <div
      className="text-xl sm:text-xl md:text-5xl p-5 text-gray-800"
      style={{
        backgroundImage: "url(/assets/titlebg.svg)",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "left",
        backgroundSize: "contain",
      }}>
      <span className=" header-main text-5xl">{children}</span>
    </div>
  );
};

export default TitleContainer;
