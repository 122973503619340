import axios from "axios";
import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import TitleContainer from "../components/TitleContainer";
import _ from "lodash";
const GalleryPage = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const getProjectsGallery = async () => {
    axios
      .post("https://hasura-c54b21aa.nhost.app/api/rest/gallery          ")
      .then((res) => {
        console.log(res);
        setData(res.data.school_table);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    getProjectsGallery();
  }, []);
  return (
    !loading && (
      <Layout>
        <TitleContainer>Gallery</TitleContainer>
        <div className=" flex flex-col items-start justify-start">
          <div className=" grid grid-cols-1 md:grid-cols-2 gap-4  items-start justify-start ">
            {data.map(
              (item) =>
                !_.isEmpty(item.user.project_galleries) &&
                item.user.project_galleries.map((photo) => (
                  <div
                    key={photo.id}
                    className=" flex flex-col space-y-4 items-center ">
                    <img
                      className=" h-44 w-64  object-cover"
                      src={`https://backend-c54b21aa.nhost.app/storage/o/${photo.photo}`}
                      alt=""
                    />
                    <span className=" font-bold text-lg">
                      {item.school_name}
                    </span>
                  </div>
                ))
            )}
          </div>
        </div>
      </Layout>
    )
  );
};

export default GalleryPage;
