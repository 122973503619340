import { gql } from "@apollo/client";

export const GET_MAIN_HEADER = gql`
  query MyQuery {
    mainHeaders {
      home
      partner
      projectdetail
      toolbox
      contact
      about
      leader
      gallery
      logo {
        url
      }
    }
  }
`;

export const LANDING_PAGE_CONTENT = gql`
  query MyQuery {
    landingPages {
      heroPhoto {
        url
      }
      heroTextBackground {
        url
      }
      heroMessage
      bodyTopDesc1
      bodyTopDesc2
      bodyTopDesc3
      bodyTopDesc4
      bodyTopMainTitle
      bodyTopTitle1
      bodyTopTitle2
      bodyTopTitle3
      bodyTopTitle4
      centerBodyTitle1
      centerBodyDesc1
      centerBodyImage1 {
        url
      }
      centerBodyTitle2
      centerBodyDesc2
      centerBodyImage2 {
        url
      }
      centerBodyTitle3
      centerBodyDesc3
      centerBodyImage3 {
        url
      }
      actionSectionTitle
      actionSectionButtonLabel
      actionSectionBackground {
        url
      }
    }
  }
`;

export const GET_TOOLBOX_LIST = gql`
  query MyQuery {
    toolboxArticles(orderBy: order_ASC) {
      id
      title
      order
    }
    toolboxPages {
      text {
        html
      }
      image {
        url
      }
    }
  }
`;

export const GET_ALL_TOOLBOX_VIDEO = gql`
  query MyQuery {
    toolboxMedias {
      link
      mediaType
      title
      id
      iconImage {
        url
      }
    }
  }
`;

export const GET_ALL_TOOLBOX_BOOKS = gql`
  query MyQuery {
    toolboxBooks {
      about
      id
      link
      topic
      title
      author
      grade
      year
    }
  }
`;

export const GET_BLOG_ITEM = gql`
  query MyQuery($id: ID) {
    blogPost(where: { id: $id }) {
      author
      createdAt
      title
      text {
        html
      }
      icon {
        url
      }
    }
  }
`;
export const GET_TOOLBOX_ITEM = gql`
  query MyQuery($id: ID) {
    toolboxArticle(where: { id: $id }) {
      longText {
        html
      }
      videoFilter
      image {
        url
      }
      id
      publishedAt
      shortText
      title
      updatedAt
      titleDocument1
      titleDocument2
      titleDocument3
      titleDocument4
      titleDocument5
      titleDocument6
      document6
      document5
      document4
      document3
      document2
      document1
    }
  }
`;

export const GET_ALL_PARTNERS = gql`
  query MyQuery {
    partners(orderBy: order_ASC) {
      link
      id
      name
      logo {
        url
      }
      order
    }
  }
`;

export const GET_ABOUT_PAGE_CONTENT = gql`
  query MyQuery {
    aboutPages {
      title
      text {
        html
      }
      image {
        url
      }
      secondTitle
      secondText {
        html
      }
      secondImage1 {
        url
      }
      secondImage2 {
        url
      }
      thirdTitle
      thirdText {
        html
      }
      thirdImage1 {
        url
      }
      thirdImage2 {
        url
      }
      thirdImage3 {
        url
      }
    }
  }
`;

export const GET_CONTACT_PAGE = gql`
  query MyQuery {
    contacts {
      email
      facebookUrl
      instagramUrl
      name
      phone
      address
      websiteUrl
      text {
        html
      }
    }
  }
`;

export const GET_ALL_BLOG = gql`
  query MyQuery {
    blogPosts {
      icon {
        url
      }
      title
      id
      createdAt
      author
    }
  }
`;
