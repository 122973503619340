import React from "react";
import Layout from "../components/Layout";
import { GET_TOOLBOX_LIST } from "../components/getQueries";
import { Link } from "react-router-dom";
import TitleContainer from "../components/TitleContainer";
import { useQuery } from "@apollo/client";
import _ from "lodash";
import parse from "html-react-parser";
import LoadingPage from "../components/LoadingPage";

const ToolBoxPage = () => {
  const { loading, error, data } = useQuery(GET_TOOLBOX_LIST);
  return loading ? (
    <LoadingPage />
  ) : (
    <Layout>
      <TitleContainer>Toolbox</TitleContainer>
      <div className="flex flex-row flex-wrap w-full h-full  justify-between    space-x-10 py-10 ">
        <div className="flex flex-col w-full md:w-1/2 space-y-10 ">
          <img
            src={_.last(data?.toolboxPages)?.image?.url}
            alt=""
            className=" rounded-2xl shadow-lg transition duration-1000 ease-out  transform hover:-translate-y-1 hover:scale-105"
          />
          <span className="text-xl">
            {parse(_.last(data?.toolboxPages)?.text?.html)}
          </span>
        </div>
        <div className="flex flex-col   md:w-1/3">
          <div className="flex flex-row flex-wrap ">
            {data?.toolboxArticles?.map((item) => (
              <ToolboxItem key={item?.id} id={item?.id} title={item?.title} />
            ))}
            <Link
              className=" shadow-lg w-full no-underline transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110 flex flex-col  h-32 bg-pink-600 rounded-3xl items-center text-center p-4 text-xl sm:text-xl md:text-3xl text-white cursor-pointer hover:shadow-sm mr-6 mb-6 justify-center"
              to="/blog">
              <div>
                <span className="font-bold ">BLOG</span>
              </div>
            </Link>
            <Link
              className=" shadow-lg w-full no-underline transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110 flex flex-col  h-32 bg-pink-600 rounded-3xl items-center text-center p-4 text-xl sm:text-xl md:text-3xl text-white cursor-pointer hover:shadow-sm mr-6 mb-6 justify-center"
              to="/toolboxvideo">
              <div>
                <span className="font-bold ">VIDEOS</span>
              </div>
            </Link>
            <Link
              to="/toolboxbook"
              className=" shadow-lg w-full no-underline transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110 flex flex-col  h-32 bg-pink-600 rounded-3xl items-center text-center p-4 text-xl sm:text-xl md:text-3xl text-white cursor-pointer hover:shadow-sm mr-6 mb-6 justify-center">
              <div>
                <span className="font-bold ">BOOKS</span>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ToolBoxPage;

const ToolboxItem = ({ title, id }) => {
  return (
    <Link
      to={`/view/${id}`}
      className=" shadow-lg w-full no-underline transition duration-500  ease-in-out transform hover:-translate-y-1 hover:scale-110 flex flex-col  h-32 bg-pink-600 rounded-3xl items-center text-center p-4 text-xl sm:text-2xl md:text-3xl text-white cursor-pointer hover:shadow-sm mr-6 mb-6 justify-center">
      <div>
        <span className="font-bold header-main  ">{title.toUpperCase()}</span>
      </div>
    </Link>
  );
};
