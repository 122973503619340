import { useQuery } from "@apollo/client";
import React from "react";
import { GET_ABOUT_PAGE_CONTENT } from "../components/getQueries";
import Layout from "../components/Layout";
import TitleContainer from "../components/TitleContainer";
import _ from "lodash";
import parse from "html-react-parser";
import LoadingPage from "../components/LoadingPage";

const AboutPage = () => {
  const { loading, error, data } = useQuery(GET_ABOUT_PAGE_CONTENT);
  console.log(data);
  return loading ? (
    <LoadingPage />
  ) : (
    <Layout>
      <TitleContainer>About us</TitleContainer>
      <div className="flex flex-col py-6">
        <div className="flex flex-col space-y-4">
          <span className="text-3xl text-gray-700">
            {_.head(data?.aboutPages)?.title}
          </span>

          <span className=" text-base text-gray-700">
            {parse(_.head(data?.aboutPages)?.text?.html)}
          </span>
          <br />
          <br />
          <div className="flex flex-col w-full items-center">
            <img
              src={_.head(data?.aboutPages)?.image?.url}
              alt=""
              className="rounded-2xl max-w-3xl w-full"
            />
          </div>
          <span className="text-3xl text-gray-700">
            {_.head(data?.aboutPages)?.secondTitle}
          </span>

          <span className=" text-base text-gray-700">
            {parse(_.head(data?.aboutPages)?.secondText?.html)}
          </span>
          <br />
          <br />
          <div className="flex flex-row flex-wrap w-full items-center justify-center">
            <img
              src={_.head(data?.aboutPages)?.secondImage1?.url}
              alt=""
              className="rounded-2xl max-w-md mr-4 mb-4"
            />
            <img
              src={_.head(data?.aboutPages)?.secondImage2?.url}
              alt=""
              className="rounded-2xl max-w-md mr-4 mb-4"
            />
          </div>
          <span className="text-3xl text-gray-700">
            {_.head(data?.aboutPages)?.thirdTitle}
          </span>

          <span className=" text-base text-gray-700">
            {parse(_.head(data?.aboutPages)?.thirdText?.html)}
          </span>
          <br />
          <br />
          <div className="flex flex-row flex-wrap w-full items-center justify-center">
            <img
              src={_.head(data?.aboutPages)?.thirdImage3?.url}
              alt=""
              className="rounded-2xl max-w-md mr-4 mb-4"
            />
            <img
              src={_.head(data?.aboutPages)?.thirdImage2?.url}
              alt=""
              className="rounded-2xl max-w-md mr-4 mb-4"
            />
            <img
              src={_.head(data?.aboutPages)?.thirdImage1?.url}
              alt=""
              className="rounded-2xl max-w-md mr-4 mb-4"
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AboutPage;
