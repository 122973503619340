import React from "react";
import Layout from "../components/Layout";
import { GET_ALL_BLOG } from "../components/getQueries";
import TitleContainer from "../components/TitleContainer";
import { useQuery } from "@apollo/client";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";
import LoadingPage from "../components/LoadingPage";
const BlogPage = () => {
  const { loading, error, data } = useQuery(GET_ALL_BLOG);
  console.log(data);
  return loading ? (
    <LoadingPage />
  ) : (
    <Layout>
      <div className="flex flex-col h-full py-2">
        <TitleContainer>Blog</TitleContainer>
      </div>

      <div className="flex flex-row flex-wrap">
        {data?.blogPosts?.map((item) => (
          <VideoItem key={item?.id} data={item} />
        ))}
      </div>
    </Layout>
  );
};

export default BlogPage;

const VideoItem = ({ data }) => {
  const history = useHistory();
  return (
    <div
      onClick={() => history.push(`/blogview/${data.id}`)}
      className="  space-y-4 w-full sm:w-full md:w-80  cursor-pointer hover:bg-gray-100 hover:shadow-md flex justify-between flex-col  bg-white border p-2 text-gray-700 text-lg  break-normal  rounded-lg mr-4 mb-4">
      <img
        src={data?.icon?.url}
        alt=""
        className=" h-44  object-top object-cover rounded-md"
      />
      <span className="p-2  bg-white rounded-md bg-opacity-60  header-main text-4xl">
        {data.title}
      </span>
      <span>{data?.author}</span>
      <span>{dayjs(data?.createdAt).format("DD/MM/YYYY")}</span>
    </div>
  );
};
