import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import MainFooter from "./components/MainFooter";
import MainHeader from "./components/MainHeader";
import AppProvider from "./context/AppContext";
import AboutPage from "./pages/AboutPage";
import BlogPage from "./pages/BlogPage";
import BlogViewPage from "./pages/BlogViewPage";
import ContactPage from "./pages/ContactPage";
import DetailPage from "./pages/DetailPage";
import GalleryPage from "./pages/GalleryPage";
import HomePage from "./pages/HomePage";
import LeaderBoardPage from "./pages/LeaderBoardPage";
import PartnersPage from "./pages/PartnersPage";
import ToolboxBookPage from "./pages/ToolboxBookPage";
import ToolBoxPage from "./pages/ToolBoxPage";
import ToolboxVideoPage from "./pages/ToolboxVideoPage";
import ToolboxViewerPage from "./pages/ToolboxViewerPage";
import UserPage from "./pages/UserPage";
import ProgressViewPage from "./pages/ProgressViewPage";
function App() {
  return (
    <div className="flex flex-col  h-full   relative   items-center  overflow-hidden   px-4 ">
      <AppProvider>
        <Router>
          <MainHeader />
          <div className="flex flex-col z-10   w-full   items-center  overflow-hidden   ">
            <Switch>
              <Route path="/about">
                <AboutPage />
              </Route>
              <Route path="/users">
                <UserPage />
              </Route>
              <Route path="/projectdetail">
                <DetailPage />
              </Route>
              <Route path="/partners">
                <PartnersPage />
              </Route>
              <Route path="/toolbox">
                <ToolBoxPage />
              </Route>
              <Route path="/view/:id">
                <ToolboxViewerPage />
              </Route>
              <Route path="/toolboxvideo">
                <ToolboxVideoPage />
              </Route>
              <Route path="/toolboxbook">
                <ToolboxBookPage />
              </Route>
              <Route path="/blog">
                <BlogPage />
              </Route>
              <Route path="/leader">
                <LeaderBoardPage />
              </Route>
              <Route path="/gallery">
                <GalleryPage />
              </Route>
              <Route path="/blogview/:id">
                <BlogViewPage />
              </Route>
              <Route path="/contact">
                <ContactPage />
              </Route>
              <Route path="/progress/:id">
                <ProgressViewPage />
              </Route>

              <Route path="/">
                <HomePage />
              </Route>
            </Switch>
          </div>
          {/* <MainFooter /> */}
        </Router>
      </AppProvider>
      <div className=" absolute z-0 top-0 left-0 ">
        <img
          src="/assets/headerbg.svg"
          className="w-screen min-h-full overflow-hidden"
          alt=""
        />
      </div>
    </div>
  );
}

export default App;
