import { useQuery } from "@apollo/client";
import React from "react";
import { GET_ALL_PARTNERS } from "../components/getQueries";
import Layout from "../components/Layout";
import LoadingPage from "../components/LoadingPage";
import TitleContainer from "../components/TitleContainer";

const PartnersPage = () => {
  const { loading, error, data } = useQuery(GET_ALL_PARTNERS);
  console.log(data);
  return loading ? (
    <LoadingPage />
  ) : (
    <Layout>
      <TitleContainer>Partners</TitleContainer>
      <div className="flex flex-col py-6">
        <div className="flex flex-col space-y-4">
          {data?.partners.map((partner) => (
            <PartnerBox data={partner} key={partner?.id} />
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default PartnersPage;

const PartnerBox = ({ data }) => {
  return (
    <div
      className="flex flex-row flex-wrap w-full p-4 border-b items-center hover:bg-gray-100 cursor-pointer "
      onClick={() => window.open(data?.link, "_blank")}>
      <img
        src={data?.logo?.url}
        className=" w-72 mr-10 border p-2 rounded-2xl"
        alt=""
      />
      <span className="text-3xl text-gray-700 header-second">{data?.name}</span>
    </div>
  );
};
