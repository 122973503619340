import React from "react";
import Layout from "../components/Layout";
import { GET_ALL_TOOLBOX_VIDEO } from "../components/getQueries";
import _ from "lodash";
import TitleContainer from "../components/TitleContainer";
import { useQuery } from "@apollo/client";
const ToolboxVideoPage = ({ filter }) => {
  const { loading, error, data } = useQuery(GET_ALL_TOOLBOX_VIDEO);

  return (
    !loading && (
      <Layout>
        <div className="flex flex-col h-full py-2">
          <TitleContainer>Videos</TitleContainer>
        </div>
        <div className="flex flex-row flex-wrap">
          {filter
            ? _.filter(data?.toolboxMedias, function (o) {
                return o.title.toUpperCase() === filter.toUpperCase();
              }).map((item) => (
                <VideoItem
                  key={item?.id}
                  title={item?.title}
                  mediaType={item?.mediaType}
                  link={item?.link}
                  image={item?.iconImage?.url}
                />
              ))
            : data?.toolboxMedias.map((item) => (
                <VideoItem
                  key={item?.id}
                  title={item?.title}
                  mediaType={item?.mediaType}
                  link={item?.link}
                  image={item?.iconImage?.url}
                />
              ))}
        </div>
      </Layout>
    )
  );
};

export default ToolboxVideoPage;

const VideoItem = ({ title, mediaType, link, image }) => {
  return (
    <div
      onClick={() => window.open(link, "_blank")}
      style={{
        backgroundImage: `url(${image})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top",
        backgroundSize: "cover",
      }}
      className=" h-44 w-44 cursor-pointer hover:bg-blue-700 hover:shadow-md flex justify-between flex-col  bg-blue-500 p-2 text-white text-lg break-normal  rounded-lg mr-4 mb-4">
      <span className="p-2 bg-gray-700 rounded-md bg-opacity-60 text-sm">
        {" "}
        {title}
      </span>{" "}
      <span>{mediaType}</span>
    </div>
  );
};
