import { useQuery } from "@apollo/client";
import React from "react";
import { useParams } from "react-router-dom";
import { GET_BLOG_ITEM } from "../components/getQueries";
import Layout from "../components/Layout";
import parse from "html-react-parser";

const BlogViewPage = () => {
  const { id } = useParams();
  const { loading, error, data } = useQuery(GET_BLOG_ITEM, {
    variables: { id: id },
  });

  return (
    !loading &&
    id && (
      <Layout>
        <div className="flex flex-col w-full items-center">
          <div className="  flex flex-col w-full  p-4 max-w-3xl pt-10 space-y-8 ">
            <div className="py-16">
              <span className=" text-5xl font-bold text-gray-800 header-main">
                {data?.blogPost?.title}
              </span>
              <div className="flex flex-row w-full justify-between items-center p">
                <span>{data?.blogPost?.author}</span>
              </div>
            </div>
            <div className="body-main">{parse(data?.blogPost?.text?.html)}</div>
          </div>
        </div>
      </Layout>
    )
  );
};

export default BlogViewPage;
