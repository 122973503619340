import { useQuery } from "@apollo/client";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { GET_ALL_PARTNERS } from "../components/getQueries";
import Layout from "../components/Layout";
import LoadingPage from "../components/LoadingPage";
import TitleContainer from "../components/TitleContainer";

import moment from "moment";
import _ from "lodash";
import { useHistory } from "react-router-dom";
const LeaderBoardPage = () => {
  const history = useHistory();
  const [leaderBoard, setLeaderBoard] = useState([]);
  const [schoolData, setSchoolData] = useState({});
  const [contactData, setContactData] = useState({});
  const [projectGoals, setProjectGoals] = useState([]);

  const [projectProgressData, setProjectProgressData] = useState([]);
  const getLeaderBoard = async () => {
    axios
      .get("https://hasura-c54b21aa.nhost.app/api/rest/getleaderboard")
      .then((res) => {
        console.log(res);
        setLeaderBoard(res?.data?.leader_board);
      })
      .catch((error) => console.log(error));
  };

  const getProjectProgressData = (id) => {
    console.log(id);
    const school = { school_id: id };
    const headers = {
      "content-type": "application/json",
      "x-hasura-role": "public",
    };
    axios
      .post(
        "https://hasura-c54b21aa.nhost.app/api/rest/schoolproject/progress",
        school,
        {
          headers,
        }
      )
      .then((res) => {
        console.log(res);
        setProjectGoals(res.data.project_goals);
        setProjectProgressData(res.data.project_progress);
        setSchoolData(res.data.school_project_by_pk);
        setContactData(_.head(res.data.school_table));
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    getLeaderBoard();
  }, []);

  return (
    <Layout>
      <TitleContainer>LeaderBoard</TitleContainer>
      <div className=" flex flex-col items-center space-y-4 w-full">
        <img
          className=" w-52 rounded-lg"
          src="https://graphly.io/wp-content/uploads/leaderboards-podium-star.jpg"
          alt=""
        />
        {_.isEmpty(schoolData) && (
          <span className=" text-2xl font-bold font-body">
            Click on each project to see more detail info
          </span>
        )}
        {_.isEmpty(schoolData) && (
          <div className="flex flex-col  max-w-3xl w-full items-center space-y-4 cursor-default">
            {leaderBoard.map((item, index) => (
              <div
                onClick={() => getProjectProgressData(item.name.id)}
                key={index}
                className="flex p-2 cursor-pointer hover:bg-gray-300 flex-row text-lg text-gray-700 font-light w-full   justify-between border-b-2 bg-gray-50">
                <span>{item?.name.school_name}</span>
                <span> {item?.project_final_points}</span>
              </div>
            ))}
          </div>
        )}
        {!_.isEmpty(schoolData) && (
          <div>
            <button className="btn-generic" onClick={() => setSchoolData({})}>
              Back to list
            </button>
            <div className="flex flex-col space-y-4 w-full  bg-gray-50 p-3 rounded-md shadow cursor-default hover:bg-blue-50 ">
              <div className="flex flex-row flex-wrap justify-between">
                <span className="form-title-xlarge text-blue-900">
                  {schoolData?.project_title}
                </span>
                {schoolData?.project_ended &&
                  schoolData?.project_final_points !== 0 && (
                    <span className="form-title-xlarge text-blue-900 p-2 rounded-md bg-blue-200">
                      final point:{schoolData?.project_final_points}
                    </span>
                  )}
              </div>
              <div className="w-full py-4 mb-6 flex flex-col space-y-2">
                <span className="text-xl font-light text-gray-600">
                  <strong>School Name:</strong>{" "}
                  <span className="text-xl">{contactData?.school_name}</span>
                </span>
                <span className="text-xl font-light text-gray-600">
                  <strong>School admin:</strong>{" "}
                  <span className="text-xl">
                    {contactData?.school_contact_name}
                  </span>
                </span>
                <span className="text-xl font-light text-gray-600">
                  <strong>Project description:</strong>{" "}
                  <span className="text-sm">{schoolData?.project_desc}</span>
                </span>
                <span className="text-xl font-light text-gray-600">
                  <strong>Project final goal:</strong>{" "}
                  <span className="text-sm">
                    {schoolData?.project_final_goal}
                  </span>
                </span>
                <div className="flex flex-row space-x-2 items-start flex-wrap">
                  <div className=" w-full max-w-sm">
                    <span className="text-xl font-light text-gray-700">
                      Project start date:{" "}
                      <strong>{schoolData?.project_start}</strong>
                    </span>
                    <br />
                    <span className="text-xl font-light text-gray-700">
                      Project end date:{" "}
                      <strong>{schoolData?.project_end}</strong>
                    </span>
                  </div>
                  <span className="text-xl font-light text-gray-600">
                    Project no. of students:{" "}
                    <strong>{schoolData.pupils_count}</strong>
                  </span>
                </div>
                {!schoolData.project_ended ? (
                  <div>
                    {moment(schoolData?.project_end).diff(
                      moment(new Date()),
                      "days"
                    ) > 0 && (
                      <span className="text-2xl text-gray-700 font-light">
                        {" "}
                        {moment(schoolData?.project_end).diff(
                          moment(new Date()),
                          "days"
                        )}{" "}
                        days left till end of project
                      </span>
                    )}
                  </div>
                ) : (
                  <span className="text-2xl  font-light p-2 rounded-md bg-green-500 text-white">
                    This project ended at {schoolData?.project_ended_date}
                  </span>
                )}

                <div className="flex flex-col p-2 border rounded-xl bg-gray-200 border-gray-700">
                  <span className="text-2xl   bg-pink-500 p-2 rounded-xl text-white ">
                    Project goals
                  </span>

                  <div className="flex flex-col space-y-2 w-full ">
                    <div className="flex flex-row w-full space-x-2">
                      <div className="flex flex-col w-64 p-2 ">
                        <span className=" text-xl text-gray-700 font-light">
                          Goal/Objective{" "}
                        </span>
                      </div>
                      <div className="flex flex-col w-64 p-2  ">
                        <span className=" text-xl text-gray-700 font-light">
                          Expected outcome
                        </span>
                      </div>
                      <div className="flex flex-col w-64 p-2  ">
                        <span className=" text-xl text-gray-700 font-light">
                          Result
                        </span>
                      </div>
                    </div>

                    {projectGoals?.map((g) => (
                      <div
                        className="flex flex-row w-full space-x-2 py-2 items-center"
                        key={g.id}>
                        <div className="flex flex-col w-64 p-2 bg-gray-100 rounded-md ">
                          <span className=" text-xl text-gray-700 font-light">
                            {g.goal_objective}
                          </span>
                        </div>
                        <div className="flex flex-col w-64 p-2 bg-gray-100 rounded-md  ">
                          <span className=" text-xl text-gray-700 font-light">
                            {g.goal_outcome_expectation}
                          </span>
                        </div>
                        <div className="flex flex-col w-64 p-2 bg-gray-100 rounded-md  ">
                          <span className=" text-xl text-gray-700 font-light">
                            {g.goal_outcome_result || "Not defined"}
                          </span>
                        </div>
                        <div> </div>{" "}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div>
                {_.isEmpty(projectProgressData) ? (
                  <div className="p-2 text-xl text-gray-700 font-light">
                    There is no progress reported
                  </div>
                ) : (
                  <div className="bg-gray-200 rounded-md py-1 ">
                    <table className="w-full rounded-md       ">
                      <thead>
                        <tr className="bg-gray-200 text-gray-700">
                          <th className="w-1/3   items-start justify-center">
                            {" "}
                            <div className="text-lg  py-4 p-2">
                              Date and time
                            </div>
                          </th>
                          <th className="  items-start justify-center">
                            Title
                          </th>
                          <th className="  items-start justify-center">
                            Validated{" "}
                          </th>
                          <th className="  items-start justify-center">
                            Invalidated{" "}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {projectProgressData.map((item) => (
                          <tr
                            key={item.id}
                            className={`border-b w-full  py-12  text-gray-700 font-light hover:bg-gray-100    cursor-pointer bg-gray-50
             
                            
             
             `}
                            onClick={() =>
                              history.push(`/progress/${item.id}`)
                            }>
                            <td>
                              <div className=" text-sm  py-2 p-2">
                                {moment(item?.created_at).format(
                                  "YYYY-MM-DD HH:mm"
                                )}
                              </div>
                            </td>
                            <td>
                              <div className="text-sm  py-2  text-center">
                                {item?.progress_title}
                              </div>
                            </td>
                            <td>
                              <div className="text-sm  py-2  text-center items-center  flex flex-col">
                                {item?.validated && (
                                  <img
                                    src="/assets/icons/checkmarkgreen.png"
                                    className=" w-6 p-1 bg-green-200 rounded-full"
                                    alt=""
                                  />
                                )}
                              </div>
                            </td>
                            <td>
                              <div className="text-sm  py-2  text-center items-center  flex flex-col">
                                {item?.invalidated && (
                                  <img
                                    src="/assets/icons/closered.png"
                                    className=" w-6 p-1 bg-red-200 rounded-full"
                                    alt=""
                                  />
                                )}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default LeaderBoardPage;
