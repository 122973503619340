import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Layout from "../components/Layout";
import TitleContainer from "../components/TitleContainer";
import FormItem from "./formItem";
import moment from "moment";
import axios from "axios";
const ProgressViewPage = () => {
  const [progressDetail, setProgressDetail] = useState({});
  const { id } = useParams();
  const getProgressDetail = async (id) => {
    axios
      .post(
        "https://hasura-c54b21aa.nhost.app/api/rest/progress",
        { id: id },
        {
          "content-type": "application/json",
          "x-hasura-role": "public",
        }
      )
      .then((res) => {
        setProgressDetail(res?.data?.project_progress_by_pk);
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    id && getProgressDetail(id);
  }, [id]);

  return (
    <Layout>
      <br />
      <div className="flex flex-col space-y-4 w-full max-w-3xl">
        <Link to={`/leader`}>
          <div className="flex p-2   flex-row items-center text-base font-light space-x-2 text-gray-700">
            <img src="/assets/back.svg" alt="" />{" "}
            <span className=" bg-yellow-400 p-2 rounded-lg font-bold">
              Go back to leader board
            </span>
          </div>
        </Link>
        <div className="flex flex-row flex-wrap items-center ">
          <span className="form-title-xlarge text-gray-700 mr-4">
            project progress detail
          </span>
          <div
            className={`flex flex-row p-1 rounded-lg  border-2   ${
              progressDetail?.invalidated &&
              "bg-red-200 border-red-500  text-red-700"
            }
          
          ${
            progressDetail?.validated &&
            "bg-green-200 border-green-500 text-green-700"
          }
          `}>
            {progressDetail?.invalidated && (
              <div className="flex flex-row items-center space-x-1">
                <img src="/assets/icons/closered.png" className=" w-6" alt="" />
                <span>Invalidated</span>
              </div>
            )}

            {progressDetail?.validated && (
              <div className="flex flex-row items-center space-x-1">
                <img
                  src="/assets/icons/checkmarkgreen.png"
                  className=" w-6"
                  alt=""
                />
                <span>Validated</span>
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col space-y-4 p-2 bg-gray-50 rounded-md shadow">
          <span className=" text-3xl font-light text-gray-700">
            {progressDetail?.school_project?.project_title}
          </span>

          <span className="text-xl text-gray-700 font-light">
            Recorded at{" "}
            {moment(progressDetail?.created_at).format("YYYY-MM-DD HH:mm")}
            {"  "} by : {progressDetail?.user?.display_name}
          </span>
        </div>
        <div>
          <div className="flex flex-wrap flex-row w-full">
            <FormItem
              label="Progress title"
              item={progressDetail?.progress_title}
            />
          </div>
          <div className="flex flex-wrap flex-row w-full">
            <FormItem
              label="Progress description"
              item={progressDetail?.progress_desc}
            />
          </div>
          {progressDetail?.progress_link && (
            <div className="flex flex-wrap flex-row w-full">
              <FormItem
                label="Progress link"
                item={progressDetail?.progress_link}
              />
            </div>
          )}
          <div>
            {progressDetail?.progress_photo && (
              <div className="flex flex-wrap flex-row w-full">
                <FormItem
                  label="Progress photo"
                  item={
                    <div
                      className="cursor-pointer "
                      onClick={() =>
                        window.open(
                          `https://backend-c54b21aa.nhost.app/storage/o/${progressDetail?.progress_photo}`,
                          "_blank"
                        )
                      }>
                      <img
                        className="flex flex-col  max-w-xs rounded-md hover:shadow-lg hover:scale-105"
                        src={`https://backend-c54b21aa.nhost.app/storage/o/${progressDetail?.progress_photo}`}
                        alt=""
                      />
                      <span className=" text-gray-500 text-xs font-light italic">
                        Click on photo to see larger scale
                      </span>
                    </div>
                  }
                />
              </div>
            )}
          </div>
        </div>
        {!progressDetail?.validated && !progressDetail?.invalidated && (
          <div className="flex flex-row space-x-2 items-center w-full justify-end">
            <Link to={`/leader`}>
              {" "}
              <button className="btn-generic-gray">Go back</button>
            </Link>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default ProgressViewPage;
